import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <div className="Footer">
          <div className="Footer__contact">Angelika Elsener | Körperarbeit bewegt berührt | 8132 Egg | Mobile: <a href={"tel:078 797 25 50"}>078 797 25 50</a> | <a href={"mailto:info@angelika-elsener.ch"}>info@angelika-elsener.ch</a></div>
          <div className="Footer__impressum"><a href="/impressum">Impressum</a></div>
          <div className="Footer__impressum"><a href="Datenschutzerklärung.pdf" target='_blank'>Datenschutz</a></div>
          <div className="Footer__copyright">© Copyright {new Date().getFullYear()} <a href="https://dasworkspace.ch" target="_blank"><strong>DAS</strong>Workspace</a></div>
        </div>
      </footer>
    )
  }
}

export default Footer
