import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import './styles.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import Header from "../components/Header";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div className="Layout">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
