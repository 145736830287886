import React from 'react'
import Hamburger from 'react-hamburgers';
import logo from '../img/Logo_100.png'
import {Link} from "gatsby";


const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    };
  }

  render() {
    return (
      <div className="Header__container">
        <div className="Header">
          <div className="Header__logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="Header__title__wrapper">
            <div className="Header__title">
              <div className="Header__title__main">
                Angelika Elsener
              </div>
              <div className="Header__title__sub">
                Körperarbeit bewegt berührt
              </div>
            </div>
          </div>
          <div className="Header__nav">
            <Hamburger
              active={this.state.active}
              type="slider"
              onClick={() => this.setState({ active: !this.state.active })}
            />
          </div>
        </div>
        <div className={this.state.active ? "Navigation Navigation--open" : "Navigation"}>
          <Link className="Navigation__item" to="/">Willkommen</Link>
          <Link className="Navigation__item" to="/move">Bewegt</Link>
          <Link className="Navigation__item" to="/touch">Berührt</Link>
          <Link className="Navigation__item" to="/about">Über mich</Link>
          <Link className="Navigation__item" to="/contact">Kontakt</Link>
        </div>
      </div>
    )
  }
}

export default Header
